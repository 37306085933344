import * as React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";

const Wrapper = styled.div`
  height: 100vh;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: end;

  @media (max-width: 815px) {
    padding: 2rem;
    gap: 3rem;
  }

  @media (max-width: 524px) {
    padding: 1rem;
    gap: 3rem;
  }
`;

const Heading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0rem 1rem;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 500px) {
    padding: 0;
    padding-bottom: 0.5rem;
  }
`;

const HeadingPrimary = styled.h1`
  font-size: 15rem;
  max-width: 80rem;
  font-weight: 400;

  @media (max-width: 1000px) {
    font-size: 14rem;
  }

  @media (max-width: 880px) {
    font-size: 12rem;
  }

  @media (max-width: 780px) {
    font-size: 10rem;
  }

  @media (max-width: 600px) {
    font-size: 18vw;
  }
`;

const HeadingTag = styled.p`
  font-size: 2rem;
  margin-left: 2rem;
  width: 13rem;
  line-height: 29px;

  @media (max-width: 815px) {
    width: 20rem;
  }

  @media (max-width: 700px) {
    width: 23rem;
  }

  @media (max-width: 490px) {
    font-size: 1.8rem;
    width: 100%;
  }
`;

const Text = styled.div`
  padding: 0rem 1rem;
  width: 80rem;
  line-height: 2rem;

  @media screen and (max-width: 1200px) {
    width: 70%;
  }

  @media screen and (max-width: 600px) {
    width: 80%;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const Paragraph = styled.p`
  font-size: 1.8rem;
  line-height: 3rem;

  @media screen and (max-width: 600px) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
`;

const Index = () => {
  return (
    <Layout>
      <Wrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Tochukwu Nwanguma - Experienced Software Engineer</title>
          <meta
            name="description"
            content="Tochukwu Nwanguma is a top-rated software engineer based in Lagos. With expertise in frontend, backend, he brings business ideas to life with cutting-edge technologies."
          />
          <meta
            name="keywords"
            content="frontend engineer, software engineer, fullstack engineer, engineers in Lagos, fintech engineer, Tochukwu Nwanguma, Lagos developer, Lagos software engineer, fintech solutions, business technology"
          />
          <link rel="canonical" href="https://www.tochukwunwanguma.com" />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Person",
                "name": "Tochukwu Nwanguma",
                "jobTitle": "Fullstack Developer",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "Lagos",
                  "addressCountry": "Nigeria"
                },
                "description": "Tochukwu Nwanguma is a top-rated software engineer based in Lagos. With expertise in frontend, backend, he brings business ideas to life with cutting-edge technologies.",
                "url": "https://www.tochukwunwanguma.com"
              }
            `}
          </script>
        </Helmet>
        <Text>
          <Paragraph>Hello, I'm</Paragraph>
        </Text>
        <Heading>
          <HeadingPrimary>Tochukwu</HeadingPrimary>
          <HeadingTag>
            Fullstack Developer. Builder. Writer sometimes.
          </HeadingTag>
        </Heading>
        <Text>
          <Paragraph>
            My superpower is bringing business ideas to life. With 4+ years of
            hands-on experience, my skills span the entire stack, from crafting
            pixel-perfect user interfaces with HTML, CSS, JavaScript,
            TypeScript, React and NextJS to designing scalable server-side
            solutions using Node.js, Docker and more.
          </Paragraph>
        </Text>
      </Wrapper>
    </Layout>
  );
};

export default Index;
